import React from 'react';
import { Grid, Image } from 'semantic-ui-react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './homeAsSeenIn.module.scss';

export default function HomeAsSeenIn() {
  return (
    <a href='/Media'>
    <div className={styles["home__asseenin__container"]}>
      <h3 className={`${styles["home__asseenin__header"]} ease-up`}>As seen in</h3>
      <Grid columns={5} stackable verticalAlign='middle' >
        <Grid.Column className={'ease-up'}>
          <Image src={'https://cdn.catalist.co.nz/test/natbr.png'} className={styles["home__asseenin__logo"]}/>
        </Grid.Column>
        <Grid.Column className={'ease-up'}>
          <Image src={'https://cdn.catalist.co.nz/test/nzh.png'} className={styles["home__asseenin__logo"]}/>
        </Grid.Column>
        <Grid.Column className={'ease-up'}>
          <Image src={'https://cdn.catalist.co.nz/test/stuff.png'} className={styles["home__asseenin__logo"]} style={{maxWidth:'70%'}}/>
        </Grid.Column>
        <Grid.Column className={'ease-up'}>
          <Image src={'https://cdn.catalist.co.nz/test/spinoff.png'} className={styles["home__asseenin__logo"]}/>
        </Grid.Column>
        <Grid.Column className={'ease-up'}>
          <Image src={'https://cdn.catalist.co.nz/test/rnz.png'} className={styles["home__asseenin__logo"]}/>
        </Grid.Column>
      </Grid>
    </div>
    </a>
  );
}
