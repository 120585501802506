import React from 'react';
import { Button } from 'semantic-ui-react';

import styles from './homeMarketPlace.module.scss';

export default function HomeMarketPlace() {

	return (
		<div className={styles["marketplace__container"]}>
			<div className={`${styles["marketplace__banner_container"]} ease-up`}>		
				<p className={styles["core__heading"]}>
					Our marketplace makes it easier to <b><i>prosper together.</i></b> 
				</p>
				<p className={styles["core__paragraph"]}>
					Catalist is the simplest way for businesses and investors to connect and grow. Low-cost trading, better liquidity, greater transparency - and we're just getting started.
				</p>
				<p>
					<Button 
						style={{marginTop: '40px'}}
						className={'custom-orange-btn'}
						href='/marketplace'>
							View marketplace
					</Button>
				</p>
			</div>
			<div className={styles["marketplace__image__container"]}>
				<div className={`${styles["marketplace__image"]} ease-up`}/>
			</div>
		</div>
	)
}
