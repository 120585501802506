import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';

import styles from './homeAdvantages.module.scss';

export default function HomeAdvantages() {

  return (
    <div className={styles["homeadvantages__content__container"]}>
      <Grid columns={4} stackable>
        <Grid.Column className={'ease-up'}>
          <Icon name="check circle outline" size="big"/>
          <h3>Licensed and regulated</h3>
          <p className={styles["core__paragraph"]}>Catalist is New Zealand's only licensed and regulated stock exchange designed for growth businesses.</p>
        </Grid.Column>
        <Grid.Column className={'ease-up'}>
          <Icon name="gem outline" size="big"/>
          <h3>New investment opportunities</h3>
          <p className={styles["core__paragraph"]}>Connecting more investors with New Zealand's growing businesses, earlier.</p>
        </Grid.Column>
        <Grid.Column className={'ease-up'}>
          <Icon name="info" size="big"/>
          <h3>Transparent information</h3>
          <p className={styles["core__paragraph"]}>Investors can be confident in the information they're getting, while the time and budget burden on businesses to disclose information is minimised compared to a traditional stock exchange.</p>
        </Grid.Column>
        <Grid.Column className={'ease-up'}>
          <Icon name="calendar alternate outline" size="big"/>
          <h3>Regular trading opportunities</h3>
          <p className={styles["core__paragraph"]}>Capital raising and secondary trading via auctions where investors can buy or sell investments.</p>
        </Grid.Column>
      </Grid>
    </div>
  );
}
