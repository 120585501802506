import { React, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import CountUp, { useCountUp }  from 'react-countup';

import { get } from 'utils/apiClient';
import styles from './homeStats.module.scss';

export default function HomeStats() {
  const [stats, setStats] = useState({});

  const fetchHomeStats = async () => {
    const response = await get(`/publicContent/getHomePageStats`);
    if(response.success) {
      const homeStats = response.result;
      setStats(homeStats)
    }
  }

  useEffect(() => {
    fetchHomeStats();
  }, []);


  return (
    <>
      <div className={styles["homestats__content__container"]}>
        <h3 className={`${styles["stats__header"]} ease-up`}>Primed for growth</h3>
        <Grid columns={4} stackable className={styles["stats__grid"]}>
          <Grid.Column className={'ease-up'}>
            <h1>
              <CountUp
                prefix="$"
                suffix="B+"
                end={stats?.valueUnderAdministration ? stats?.valueUnderAdministration : 1}
                duration={1}
                enableScrollSpy={true}
                decimals={2}
              />
            </h1>
            <p className={styles["core__paragraph"]}>
              Value under administration
              <br/>
              <i style={{fontSize: '12px'}}>(all markets)</i>
            </p>
          </Grid.Column>

          <Grid.Column className={'ease-up'}>
            <h1>
              <CountUp
                prefix="$"
                suffix="M+"
                end={stats?.valueTransacted ? stats?.valueTransacted : 50}
                duration={1}
                enableScrollSpy={true}
              />
            </h1>
            <p className={styles["core__paragraph"]}>
              Value transacted
              <br/>
              <i style={{fontSize: '12px'}}>(all markets)</i>
            </p>
          </Grid.Column>

          <Grid.Column className={'ease-up'}>
            <h1>
              <CountUp
                suffix="+"
                end={stats?.financialProducts ? stats?.financialProducts : 200}
                duration={1}
                enableScrollSpy={true}
              />
            </h1>
            <p className={styles["core__paragraph"]}>
              Financial products
              <br/>
              <i style={{fontSize: '12px'}}>(all markets)</i>
            </p>
          </Grid.Column>

          <Grid.Column className={'ease-up'}>
            <h1>
              <CountUp
                suffix="+"
                end={stats?.numberOfAuctions ? stats?.numberOfAuctions : 100} 
                duration={1}
                enableScrollSpy={true}
              />
            </h1>
            <p className={styles["core__paragraph"]}>
              Trading events
              <br/>
              <i style={{fontSize: '12px'}}>(all markets)</i>
            </p>
          </Grid.Column>
        </Grid>
      </div>
      <div className={styles["homestats__polygon"]}/>
    </>
  );
}
