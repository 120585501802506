import React from 'react';
import { Grid } from 'semantic-ui-react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './homeBlogs.module.scss';

export default function HomeBlogs() {

  // TODO
  // Fetch blog data from db

  const mockData = [
    {
      image: 'https://cdn.catalist.co.nz/test/umbrella_corp.png',
      title: 'Shaking up the New Zealand investment',
      text: 'Gibraltar as a girl where I was a Flower of the mountain yes when I put the rose in my hair like the Andalusian girls used or shall I wear a red yes and how he kissed me under the Moorish wall and I thought well as well him as another and then I asked him with my eyes to ask again yes and then he asked me would I yes to say yes my mountain flower and first I put my arms around him yes and drew him down to me so he could feel.',
      link: 'http://www.plod.co.nz'
    },
    {
      image: 'https://cdn.catalist.co.nz/test/umbrella_corp.png',
      title: 'Top tips for investing in your tax refund',
      text: 'this is how to make a good medicine for a cold; this is how to make a good medicine to throw away a child before it even becomes a child; this is how to catch a fish.',
      link: 'http://www.plod.co.nz'
    },
    {
      image: 'https://cdn.catalist.co.nz/test/umbrella_corp.png',
      title: 'Interview with Verbal Kint',
      text: 'There is no immunity from me. You atone with me or the world you live in becomes the hell you fear in the back of your tiny mind. Every criminal I have put in prison, every cop who owes me a favor',
      link: 'http://www.plod.co.nz'
    },
    {
      image: 'https://cdn.catalist.co.nz/test/umbrella_corp.png',
      title: 'Rant from the Narrator',
      text: 'Advertising has us chasing cars and clothes, working jobs we hate so we can buy stuff we dont need. Were the middle children of history, man. No purpose or place. We have no Great War.',
      link: 'http://www.plod.co.nz'
    },
  ]

  // Use this to set the blog card height
  const truncLimit = 180

  const truncateText = (data) => {
    if (data.length <= truncLimit) return data;
    const subString = data.slice(0, truncLimit-1);
    const strResult = subString.slice(0, subString.lastIndexOf(' '))
    if(['.','?','!',',',':','-','(',')','[',']','{','}'].indexOf(strResult.charAt(strResult.length)) > -1) return strResult.slice(0, strResult.length);
    return strResult + '...';
  }

  return (
    <>
      {mockData && mockData.length >= 3 &&
        <>
          <div className={styles["home__blogs__container"]}>
            <h3 className={styles["home__blogs__header"]}>The latest on the exchange</h3>
            <Grid columns={4} stackable>
            {mockData?.map((card, index) => (
              <Grid.Column className={styles["home__blogs__card"]} key={index}>
                <a href={card.url}>

                  {/* TODO */}
                  {/* Add db images here....  */}
                  <div className={styles["home__blogs__image"]} style={{'background-image': 'url(https://cdn.catalist.co.nz/test/people_landscape_2.png)'}}/>
                    <div className={styles["home__blogs__text"]}>
                      <p><h3>{card.title}</h3></p>
                      <p className={styles["core__paragraph"]}>{`${truncateText(card.text)}`}</p>
                    </div>
                    <p className={styles["home__blogs__link"]}>
                      More
                    </p>
                </a>
              </Grid.Column>
              ))}
            </Grid>
          </div>
        </>
      }
    </>
  );
}
