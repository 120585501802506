import React, {useState, useEffect, useContext} from 'react';
import Head from 'next/head';
import { Container, Modal, Button } from 'semantic-ui-react';
import { useRouter } from 'next/router'

import { checkAuthRoute } from '../utils/auth';
import HomeLayout from 'components/Layouts/HomeLayout';
import HomeTitle from 'components/Home/HomeTitle';
import HomePageFeature from 'components/Home/PublicContent';
import HomeStats from 'components/Home/HomeStats';
import HomeMarketPlace from 'components/Home/HomeMarketPlace';
import HomeAdvantages from 'components/Home/HomeAdvantages';
import HomeHeadline from 'components/Home/HomeHeadline';
import HomeAsSeenIn from 'components/Home/HomeAsSeenIn';
import HomeBlogs from 'components/Home/HomeBlogs';
import { StandardSeo } from 'components/Universal/StandardSeo';
import { defaultSEO } from 'components/Universal/SeoConfig';
import { FBPixel } from 'components/Universal/FBPixel';
import { AppContext } from './../pages/_app';
import { get, post } from 'utils/apiClient';


const HomePage = (props) => {

  // Generic modal shows token information
  const [tokenMessage, setTokenMessage] = useState()
  const router = useRouter();

  const {handleSidebarConfigChange} = useContext(AppContext);

  const signupCheck = async () => {
    /**
     * 
     * Check for default sign up 
     * 
     */
    if(router.query.sidebar && router.query.sidebar === 'SIGNUP_INVESTOR') {
      router.replace('/', undefined, { shallow: true})
      handleSidebarConfigChange({open: true, type: 'SIGNUP_INVESTOR'})
      return;
    }

    /**
     * 
     * Check for key person sign up
     * 
     */
    if(router.query.submitToken && router.query.submitToken.length) {
      const submitToken = router.query.submitToken;
      router.replace('/', undefined, { shallow: true})
      // clear any existing
      window.localStorage.removeItem('submitToken');

      // Already logged in
      if(props.authenticated){
        setTokenMessage({
          title: 'Sorry, you cant sign up for a new account whilst logged in to Catalist',
          message: 'To sign up you need to log out first.'
        });
        return;
      }

      // Else is logged out
      const prevalidateSignupRes = await get(`/guest/prevalidate/${submitToken}`);

      if (!prevalidateSignupRes.success) {
        setTokenMessage({
          title: 'Sorry, there was a problem validating that sign up link',
          message: prevalidateSignupRes.error
        });
        return;
      }

      if (prevalidateSignupRes.success && prevalidateSignupRes.result.isValid) {
        // Use local storage 
        window.localStorage.setItem('submitToken', submitToken)
        // Open form to submit with token
        handleSidebarConfigChange({open: true, type: 'SIGNUP_INVESTOR_KEY_PERSON'});
        return;
      }
      
      // else
      setTokenMessage({
        title: 'Sorry, that sign up link doesnt work',
        message: 'We may have received your information already.'
      });
    }
    
    /**
     * 
     * Check for key person sign in
     * 
     */
    if(router.query.signInToken && router.query.signInToken.length) {
      const signInToken = router.query.signInToken;
      router.replace('/', undefined, { shallow: true})
      
      // Already logged in
      if(props.authenticated){
        const assignKeyPersonRes = await post(`/aml/assign-logged-in-key-person/${signInToken}`);
        if(assignKeyPersonRes.success) {
          // Success message
          setTokenMessage({
            title: 'You are signed in',
            message: 'Success: this logged in account has been assigned as a key person.'
          });
          return;
        };

        // else
        setTokenMessage({
          title: 'Sorry, there was a problem validating that sign in link',
          message: assignKeyPersonRes.error
        });
        return;
      }

      // Else is logged out
      const prevalidateSignInRes = await get(`/guest/prevalidate/${signInToken}`);

      if (!prevalidateSignInRes.success) {
        setTokenMessage({
          title: 'Sorry, there was a problem prevalidating that sign in link',
          message: prevalidateSignInRes.error
        });
        return;
      }

      if (prevalidateSignInRes.success && prevalidateSignInRes.result.isValid) {
        // redirect back to this page (router.asPath includes token!)
        window.location = '/api/login?redirect=' + encodeURIComponent(router.asPath);
        return;
      }

      // else
      setTokenMessage({
        title: 'Sorry, that sign in link doesnt work',
        message: 'We may have received your information already.'
      });
    }
    
    /**
     * 
     * Check for join investment group sign up
     * 
     */
    if(router.query.joinGroupSignUpToken && router.query.joinGroupSignUpToken.length) {
      const joinGroupSignUpToken = router.query.joinGroupSignUpToken;
      router.replace('/', undefined, { shallow: true})
      // clear any existing
      window.localStorage.removeItem('joinGroupSignUpToken');

      // Already logged in
      if(props.authenticated){
        setTokenMessage({
          title: 'Sorry, you cant sign up for a new account whilst logged in to Catalist',
          message: 'To sign up you need to log out first.'
        });
        return;
      }

      // Else is logged out
      const prevalidateSignupRes = await get(`/issuers/investmentGroups/prevalidate/${joinGroupSignUpToken}`);

      if (!prevalidateSignupRes.success) {
        setTokenMessage({
          title: 'Sorry, there was a problem validating that sign up link',
          message: prevalidateSignupRes.error
        });
        return;
      }

      if (prevalidateSignupRes.success && prevalidateSignupRes.result.isValid) {
        // Use local storage 
        window.localStorage.setItem('joinGroupSignUpToken', joinGroupSignUpToken)
        // Open form to submit with token
        handleSidebarConfigChange({open: true, type: 'SIGNUP_INVESTOR_JOIN_GROUP'});
        return;
      }
      
      // else
      setTokenMessage({
        title: 'Sorry, that sign up link doesnt work',
        message: 'The link may have already been used, or the investment group has been removed.'
      });
    }
    
    /**
     * 
     * Check for join investment group sign in
     * 
     */
    if(router.query.joinGroupSignInToken && router.query.joinGroupSignInToken.length) {
      const joinGroupSignInToken = router.query.joinGroupSignInToken;
      router.replace('/', undefined, { shallow: true})
      
      // Already logged in
      if(props.authenticated){
        const joinRes = await post(`/issuers/investmentGroups/join/${joinGroupSignInToken}`);
        if(joinRes.success) {
          // Success message
          setTokenMessage({
            title: 'You are signed in',
            message: 'Success: this logged in account is now an investment group member.'
          });
          return;
        };

        // else
        setTokenMessage({
          title: 'Sorry, we could not add you an the investment group',
          message: joinRes.error
        });
        return;
      }

      // Else is logged out
      const prevalidateSignInRes = await get(`/issuers/investmentGroups/prevalidate/${joinGroupSignInToken}`);

      if (!prevalidateSignInRes.success) {
        setTokenMessage({
          title: 'Sorry, there was a problem prevalidating that sign in link',
          message: prevalidateSignInRes.error
        });
        return;
      }

      if (prevalidateSignInRes.success && prevalidateSignInRes.result.isValid) {
        // redirect back to this page (router.asPath includes token!)
        window.location = '/api/login?redirect=' + encodeURIComponent(router.asPath);
        return;
      }

      // else
      setTokenMessage({
        title: 'Sorry, that sign in link doesnt work',
        message: 'The link may have already been used, or the investment group has been removed.'
      });
    }

  }

  useEffect(() => {
    signupCheck();
  }, []);

  return (
    <>
      <FBPixel />
      <StandardSeo 
        titleProp={defaultSEO.title}
        descriptionProp={defaultSEO.description}
        keywordProp={defaultSEO.keywords}
      />
      <Container fluid>
          <HomeLayout {...props}>

            {/* Generic modal to display success or error messages */}
            <Modal open={!!tokenMessage} onClose={() => { setTokenMessage(); }} centered size="small">
              { (tokenMessage && tokenMessage.title) && <Modal.Header size="large"> {tokenMessage.title} </Modal.Header> }
              { (tokenMessage && tokenMessage.message) &&
                <>
                  <Modal.Content>
                    <p>{tokenMessage.message}</p>
                    <p>If you have any questions contact hello@catalist.co.nz</p>
                  </Modal.Content>
                  <Modal.Content style={{textAlign: 'center'}}>
                    <Button onClick={() => { setTokenMessage(); }} color="blue" basic>
                      Got it!
                    </Button>
                  </Modal.Content>
                </>
              }
            </Modal>
            <HomeTitle />
            <HomePageFeature />
            <HomeStats/>
            <HomeMarketPlace/>
            <HomeAdvantages/>
            <HomeHeadline/>
            {/* TODO */}
            {/* Add query and enable home blogs component */}
            {/* <HomeBlogs/> */}
            <HomeAsSeenIn/>
            <Head>
              <meta property="og:type" content="website" />
              <meta name="facebook-domain-verification" content="zsnznfg60rwu9qiwgsixxwvlnouvgc" />
          </Head>
        </HomeLayout>
      </Container>
    </>
  );
}

export default checkAuthRoute(HomePage);
