import React from 'react';
import { Button } from 'semantic-ui-react';

import styles from './homeHeadline.module.scss';


function HomeHeadline() {
	return (
		<div className={styles["headline__background"]}>
			<div className={styles["headline__polygon"]}/>
			<div className={styles["headline__container"]}>
				<div className={styles["headline__image__container"]}>
					<div className={`${styles["headline__image"]} ease-up`}/>
				</div>
				<div className={`${styles["headline__banner_container"]} ease-up`}>		
					<p className={styles["core__heading__inverse"]}>
						Catalist is the stock exchange we've all been waiting for, catalysing the <b><i>financial success </i></b>of all New Zealanders.
					</p>
					<p style={{marginTop: '20px'}}>
						<Button 
							style={{ marginRight: '20px'}}
							className={'custom-white-btn'}
							href='/Invest'>
								Invest
						</Button>
						<Button 
							style={{marginTop: '20px'}}
							className={'custom-orange-btn'}
							href='/List'>
								List
						</Button>
					</p>
				</div>
			</div>
		</div>
	)
}

export default HomeHeadline